/**
 * = Breadcrumbs
 */

.breadcrumb-item {
  font-size: $font-size-xs;
  &,
  a {
    color: $breadcrumb-item-color;
    font-weight: $breadcrumb-font-weight;
    font-size: $font-size-xs;
    @include media-breakpoint-up(sm) {
      font-size: $font-size-sm;
    }
  }

  &.active {
    font-weight: $breadcrumb-active-font-weight;
  }

  + .breadcrumb-item {
    &:before {
      content: '\f101';
      font-family: $font-awesome-5;
      font-weight: 900;
      color: $breadcrumb-divider-color;
    }
  }
}

.breadcrumb-transparent {
  background: transparent;
  padding: 0;
}

@each $color, $value in $theme-colors {
  .breadcrumb-#{$color} {
    background: $value;
    .breadcrumb-item {
      &.active {
        color: $white;
      }
    }
    &.breadcrumb-transparent {
      background: transparent;
      .breadcrumb-item {
        &.active {
          color: $value;
        }
      }
    }
  }
}

.breadcrumb-text-light {
  .breadcrumb-item {
    &,
    a {
      color: $breadcrumb-item-light-color;
    }

    &:before {
      color: $breadcrumb-divider-light-color;
    }
  }
}
