body,
html,
.App,
#root {
  width: 100%;
  height: 100%;
  background-color: #eae9e9;
}

.search-card {
  width: 25%;
}

.centre {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
